import { createEffect, createStore, sample } from 'effector';
import { keyBy, values } from 'lodash';
import { ghApi } from '../DataService';

const $users = createStore(/** @type {Users.User[]} */ ([]));

const $permissions = createStore(/** @type {string[]} */ ([]));

export const PERMISSIONS = {
  GENERAL: {
    ADDRESS_VERIFICATION: 'ghs-portal:address-verification',
    DASHBOARDS: 'ghs-portal:dashboards',
    SPL: 'ghs-portal:spl',
    RELEASE_NOTES: 'ghs-portal:release-notes'
  },
  GHS_ADMIN: {
    BANNERS: 'ghs-portal:ghs-admin:banners',
    DASHBOARD_THUMBNAILS: 'ghs-portal:ghs-admin:dashboard-thumbnails',
    FILE_PROCESSING: 'ghs-portal:ghs-admin:file-processing',
    LOGOS: 'ghs-portal:ghs-admin:logos',
    MAILTRAK_AUTO_RATING: 'ghs-portal:ghs-admin:auto-rating',
    MAILTRAK_CLIENT_LOB: 'ghs-portal:ghs-admin:mailtrak-client-lob',
    ROLES_PERMISSIONS: 'ghs-portal:ghs-admin:roles-permissions'
  },
  RESELLER_ADMIN: {
    CREATE_CAMPAIGNS: 'ghs-portal:create-campaigns',
    EDIT_CAMPAIGNS_VERSIONS: 'ghs-portal:admin:edit-campaigns-versions',
    EDIT_CUSTOMER_LOB: 'ghs-portal:admin:edit-customer-lob',
    IN_HOME_WINDOWS: 'ghs-portal:admin:in-home-windows',
    LOGOS_CUSTOMER: 'ghs-portal:admin:logos:customer',
    LOGOS_RESELLER: 'ghs-portal:admin:logos:reseller',
    MANAGE_USERS_DATA_ACCESS_CUSTOMER: 'ghs-portal:admin:manage-users-data-access:customer',
    MANAGE_USERS_DATA_ACCESS_LOB: 'ghs-portal:admin:manage-users-data-access:lob',
    ROLES_PERMISSIONS: 'ghs-portal:admin:roles-permissions'
  }
};

/**
 * Retrieves all values from a nested object and returns them as a Set.
 *
 * @param {object} obj - The object to extract values from.
 * @returns {Set} - A Set containing all the values from the object.
 */
function getAllValues(obj) {
  const objValues = new Set();
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      const nestedValues = getAllValues(obj[key]);
      nestedValues.forEach(value => objValues.add(value));
    } else {
      objValues.add(obj[key]);
    }
  }
  return objValues;
}

/**
 * Determine if a user has permissions to use Address Verification.
 *
 * @param {string[]} permissions array of user permissions
 * @returns {boolean} whether the user has permissions to use Address Verification
 */
export const hasAddressVerificationPermission = permissions => {
  return !!permissions?.includes(PERMISSIONS.GENERAL.ADDRESS_VERIFICATION);
};

/**
 * Determine if a user has permissions to use MailTrak Client LOB display.
 *
 * @param {string[]} permissions array of user permissions
 * @returns {boolean} whether the user has permissions to use MailTrak Client LOB display
 */
export const hasMailTrakClientLobPermission = permissions => {
  return !!permissions?.includes(PERMISSIONS.GHS_ADMIN.MAILTRAK_CLIENT_LOB);
};

/**
 * Determine if a user has permissions to use MailTrak Auto Rating display.
 *
 * @param {string[]} permissions array of user permissions
 * @returns {boolean} whether the user has permissions to use MailTrak Auto Rating display
 */
export const hasMailTrakAutoRatingPermission = permissions => {
  return !!permissions?.includes(PERMISSIONS.GHS_ADMIN.MAILTRAK_AUTO_RATING);
};

/**
 * Determine if a user has permissions to view dashboards.
 *
 * @param {string[]} permissions array of user permissions
 * @returns {boolean} whether the user has permissions to view dashboards
 */
export const hasDashboardPermission = permissions => {
  return !!permissions?.includes(PERMISSIONS.GENERAL.DASHBOARDS);
};

/**
 * Determine if a user has permissions to create campaigns.
 *
 * @param {string[]} permissions array of user permissions
 * @returns {boolean} whether the user has permissions to create campaigns
 */
export const hasCreateCampaignPermission = permissions => {
  return !!permissions?.includes(PERMISSIONS.RESELLER_ADMIN.CREATE_CAMPAIGNS);
};

/**
 * Determine if a user has any internal admin permissions.
 *
 * @param {string[]} permissions array of user permissions
 * @returns {boolean} whether the user any internal admin permissions
 */
export const hasGhsAdminPermissions = permissions => {
  const systemAdminPermissions = new Set(Object.values(PERMISSIONS.GHS_ADMIN));
  return !!permissions?.some(p => systemAdminPermissions.has(p));
};

export const hasGhsAdminLogoPermissions = permissions => {
  return !!permissions?.some(p => p?.includes(PERMISSIONS.GHS_ADMIN.LOGOS));
};

export const hasGhsAdminDashboardThumbnailsPermissions = permissions => {
  return !!permissions?.some(p => p?.includes(PERMISSIONS.GHS_ADMIN.DASHBOARD_THUMBNAILS));
};

export const hasGhsAdminBannerPermissions = permissions => {
  return !!permissions?.some(p => p?.includes(PERMISSIONS.GHS_ADMIN.BANNERS));
};

/**
 * Determine if a user has any external admin permissions.
 *
 * @param {string[]} permissions array of user permissions
 * @returns {boolean} whether the user any external admin permissions
 */
export const hasAdminPermissions = permissions => {
  const adminPermissions = getAllValues(PERMISSIONS.RESELLER_ADMIN);
  return !!permissions?.some(p => adminPermissions.has(p));
};

export const hasAdminManageUsersDataAccessPermissions = permissions => {
  return (
    !!permissions?.some(p => p?.includes(PERMISSIONS.RESELLER_ADMIN.MANAGE_USERS_DATA_ACCESS_CUSTOMER)) ||
    !!permissions?.some(p => p?.includes(PERMISSIONS.RESELLER_ADMIN.MANAGE_USERS_DATA_ACCESS_LOB))
  );
};

export const hasAdminEditCampaignsVersionsPermissions = permissions => {
  return !!permissions?.some(p => p?.includes(PERMISSIONS.RESELLER_ADMIN.EDIT_CAMPAIGNS_VERSIONS));
};

export const hasAdminInHomeWindowsPermissions = permissions => {
  return !!permissions?.some(p => p?.includes(PERMISSIONS.RESELLER_ADMIN.IN_HOME_WINDOWS));
};

export const hasAdminEditCustomersLobsPermissions = permissions => {
  return !!permissions?.some(p => p?.includes(PERMISSIONS.RESELLER_ADMIN.EDIT_CUSTOMER_LOB));
};

export const hasAdminLogosPermissions = permissions => {
  return !!permissions?.some(p => p?.includes(PERMISSIONS.RESELLER_ADMIN.LOGOS_RESELLER)) || !!permissions?.some(p => p?.includes(PERMISSIONS.RESELLER_ADMIN.LOGOS_CUSTOMER));
};

export const hasAdminRolesPermissionsPermissions = permissions => {
  return !!permissions?.some(p => [PERMISSIONS.RESELLER_ADMIN.ROLES_PERMISSIONS].includes(p));
};

/**
 * Determine if a user has permissions to use Single Piece Lookup.
 *
 * @param {string[]} permissions array of user permissions
 * @returns {boolean} whether the user has permissions to use Single Piece Lookup
 */
export const hasSinglePieceLookupPermission = permissions => {
  return !!permissions?.includes(PERMISSIONS.GENERAL.SPL);
};

export const hasReleaseNotesPermission = permissions => {
  return !!permissions?.includes(PERMISSIONS.GENERAL.RELEASE_NOTES);
};

/**
 * Determine if a user has permissions to use File Processing.
 *
 * @param {Array} permissions array of user permissions
 * @returns {boolean} whether the user has permissions to use File Processing
 */
export const hasFileProcessingPermission = permissions => {
  return !!permissions?.includes(PERMISSIONS.GHS_ADMIN.FILE_PROCESSING);
};

/**
 * Retrieve the permissions for the current user from the backend.
 *
 * @type {import('effector').Effect<void, string[]>} - list of permissions for the user
 */
export const fetchPermissionsFx = createEffect(async () => {
  return ghApi.get('/permissions').then(response => response.data);
});

/** @type {import('effector').Effect<Partial<{ search: string; customerId: Reseller.Customer['id']; } & DataGrid.ServerSideParams>, PaginatedResponse<Users.User>>} */
const getUsersFx = createEffect(async params => {
  return ghApi.get('/users', { params }).then(response => response.data);
});

/** @type {import('effector').Effect<Partial<Users.User['userId']>, Users.User>} */
const getUserByIdFx = createEffect(async userId => {
  return ghApi.get(`/users/${userId}`).then(response => response.data);
});

/** @type {import('effector').Effect<string[], unknown[]>} */
const exportUsersFx = createEffect(async data => {
  return ghApi.post('/users/export', data).then(response => response.data);
});

/** @type {import('effector').Effect<string, Roles.Role[]>} */
const getUserRolesFx = createEffect(async userId => {
  return ghApi.get(`/users/${userId}/roles`).then(response => response.data);
});

/** @type {import('effector').Effect<{ userId: string; roleId: string }, unknown[]>} */
const assignRoleToUserFx = createEffect(async ({ userId, roleId }) => {
  return ghApi.post(`/users/${userId}/roles/${roleId}`).then(response => response.data);
});

/** @type {import('effector').Effect<{ userId: string; roles: string[] }, unknown[]>} */
const assignRolesToUserFx = createEffect(async ({ userId, roles }) => {
  return ghApi.patch(`/users/${userId}/roles`, roles).then(response => response.data);
});

/** @type {import('effector').Effect<{ userId: string; roleId: string }, unknown[]>} */
const deleteRoleFromUserFx = createEffect(async ({ userId, roleId }) => {
  return ghApi.delete(`/users/${userId}/roles/${roleId}`).then(response => response.data);
});

/** @type {import('effector').Effect<{ userId: string; roles: string[] }, unknown[]>} */
const deleteRolesFromUserFx = createEffect(async ({ userId, roles }) => {
  return ghApi.delete(`/users/${userId}/roles`, roles).then(response => response.data);
});

sample({
  source: $users,
  clock: getUsersFx.doneData,
  fn: (users, data) => values({ ...keyBy(users, 'userId'), ...keyBy(data.content, 'userId') }),
  target: $users
});

sample({
  source: $users,
  clock: getUserByIdFx.doneData,
  fn: (users, user) => values({ ...keyBy(users, 'userId'), [user.userId]: user }),
  target: $users
});

sample({
  clock: fetchPermissionsFx.doneData,
  target: $permissions
});

export const $$user = {
  $users,
  $permissions,

  getUsersFx,
  getUserByIdFx,
  exportUsersFx,
  getUserRolesFx,
  assignRoleToUserFx,
  assignRolesToUserFx,
  deleteRoleFromUserFx,
  deleteRolesFromUserFx
};
