import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { $$faq } from './model';
import { useUnit } from 'effector-react';
import { Accordion, Skeleton } from '@ghs/components';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function ReleaseNotesDialog() {
  const open = useUnit($$faq.$$releaseNotesDialog.$isOpen);
  const gcpVersions = useUnit($$faq.$gcpVersions);

  return (
    <Dialog
      id="release-notes-dialog"
      data-testid="release-notes-dialog"
      open={open}
      onClose={() => $$faq.$$releaseNotesDialog.close()}
      fullWidth={true}
      maxWidth="lg"
      scroll="paper"
      PaperProps={{
        sx: {
          height: '80%'
        }
      }}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: 2, pb: 0, mb: 2 }}>
        <Typography variant="h4">Release Notes</Typography>
        <IconButton onClick={() => $$faq.$$releaseNotesDialog.close()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {gcpVersions ? (
          gcpVersions.map((gcpVersion, versionIndex) => {
            return (
              <Accordion key={`gcp-version-${versionIndex}`} data-testid={`gcp-version-${versionIndex}`} heading={`${gcpVersion.releaseDate} - ${gcpVersion.description}`}>
                <List sx={{ p: 1, ml: 1 }}>
                  {gcpVersion?.releaseNotes.map((releaseNote, releaseNoteIndex) => {
                    return (
                      <ListItem
                        key={`v-${versionIndex}-release-note-${releaseNoteIndex}`}
                        data-testid={`v-${versionIndex}-release-note-${releaseNoteIndex}`}
                        sx={{ my: 0, p: 0, alignItems: 'center' }}
                        disableGutters
                      >
                        <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                          <FiberManualRecordIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={releaseNote} />
                      </ListItem>
                    );
                  })}
                </List>
              </Accordion>
            );
          })
        ) : (
          <Box id="release-notes-loading" sx={{ width: '100%', mt: 1 }}>
            <Skeleton animation={'wave'} height={50} sx={{ flexGrow: 1 }} />
            <Skeleton animation={'wave'} height={50} sx={{ flexGrow: 1 }} />
            <Skeleton animation={'wave'} height={50} sx={{ flexGrow: 1 }} />
            <Skeleton animation={'wave'} height={50} sx={{ flexGrow: 1 }} />
            <Skeleton animation={'wave'} height={50} sx={{ flexGrow: 1 }} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
