import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { useTheme } from '@mui/material/styles';
import { Tooltip as MuiTooltip } from '@mui/material';

export const tooltipSeverities = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

export const tooltipPlacements = {
  NONE: null,
  TOP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
  BOTTOM: 'bottom'
};

export const tooltipOpacities = {
  OPACITY_50: '80',
  OPACITY_60: '99',
  OPACITY_70: 'B3',
  OPACITY_80: 'CC',
  OPACITY_90: 'E6',
  OPACITY_100: 'FF'
};

const Tooltip = ({ children, title, placement, severity = tooltipSeverities.INFO, opacity = 'OPACITY_50', ...overrides }) => {
  const opacityHex = tooltipOpacities[opacity];

  // Get colors from theme based on severity
  const theme = useTheme();
  const tooltipTheme = theme.palette.toolips?.[severity.toLowerCase()];
  const bgColor = tooltipTheme?.background || theme.palette.background.paper;
  const textColor = tooltipTheme?.text || theme.palette.text.primary;

  return (
    <MuiTooltip
      title={title}
      arrow={!isNil(placement)}
      {...(placement && { placement })}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: `${bgColor}${opacityHex}`,
            color: `${textColor}`,
            '& .MuiTooltip-arrow': {
              color: `${bgColor}${opacityHex}`
            }
          }
        }
      }}
      {...overrides}
    >
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(Object.values(tooltipPlacements)),
  severity: PropTypes.oneOf(Object.keys(tooltipSeverities)),
  opacity: PropTypes.oneOf(Object.keys(tooltipOpacities))
};

export default Tooltip;
