import { Container, Grid, Typography } from '@mui/material';
import { $$bi } from '../../services/BiService/model';
import { useUnit } from 'effector-react';
import { createSearchParams } from 'react-router-dom';
import BiThumbnailCard from '../../components/BiThumbnailCard';
import CuratingMessage from './CuratingMessage';
import { UserDashboardStatus } from '../../services/BiService';

export default function DashboardsPage() {
  const sisenseInfo = useUnit($$bi.$sisenseInfo);
  const userDashboardStatus = useUnit($$bi.$userDashboardStatus);

  return (
    <Container id="dashboard-list-container">
      <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
        Dashboards
      </Typography>
      {!sisenseInfo || userDashboardStatus === UserDashboardStatus.USER_NOT_FULLY_SETUP ? (
        <CuratingMessage />
      ) : (
        <Grid container>
          {(sisenseInfo?.dashboards || [])
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(dashboard => {
              const hrefParams = createSearchParams({ dashboardId: dashboard.id }).toString();
              const hrefUrl = `/dashboard?${hrefParams}`;
              return (
                <Grid item key={dashboard.id + '-grid'} xs={12} sm={6} md={4} lg={3}>
                  <BiThumbnailCard key={dashboard.id + '-card'} {...dashboard} link={hrefUrl} />
                </Grid>
              );
            })}
        </Grid>
      )}
    </Container>
  );
}
