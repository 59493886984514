import { createEvent, createStore, sample } from 'effector';
import { spread } from 'patronum';

/**
 * @template {Record<string, unknown>} T
 * @returns {DialogFactory<T>} DialogState
 */
export function createDialog() {
  /** @type {import('effector').EventCallable<void>} */
  const reinitAll = createEvent();

  const $isOpen = createStore(/** @type {boolean} */ (false)).reset(reinitAll);
  const $isDisabled = createStore(/** @type {boolean} */ (false)).reset(reinitAll);

  const $state = createStore(/** @type {DialogState<T> | null} */ (null)).reset(reinitAll);

  /** @type {import('effector').EventCallable<void>} */
  const close = createEvent();

  /** @type {import('effector').EventCallable<(Partial<DialogState> & T) | void>} */
  const open = createEvent();

  /** @type {import('effector').EventCallable<void>} */
  const setOpen = createEvent();

  /** @type {import('effector').EventCallable<void>} */
  const setDisabled = createEvent();

  sample({
    clock: open,
    fn: () => ({ open: true, disabled: false }),
    target: spread({ open: $isOpen, disabled: $isDisabled })
  });

  sample({
    clock: close,
    fn: () => false,
    target: $isOpen
  });

  // @ts-ignore
  sample({
    clock: open.map(state => state || null),
    target: $state
  });

  sample({
    clock: $isOpen,
    filter: isOpen => isOpen === false,
    fn: () => null,
    target: $state
  });

  sample({
    clock: setDisabled,
    fn: () => true,
    target: $isDisabled
  });

  return {
    $isOpen: $isOpen.map(d => d),
    $isDisabled: $isDisabled.map(d => d),
    // @ts-ignore
    $state: $state.map(d => d),

    close,
    open,
    setOpen,
    setDisabled,

    __: {
      reinitAll
    }
  };
}
