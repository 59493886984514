import { Box, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect } from 'react';
import './App.css';
import { UserContext } from '../../context/UserContext';
import useAuth0Gate from '../../hooks/useAuth0Gate';
import useAuth0WithErrorHandling from '../../hooks/useAuth0WithErrorHandling';
import { getTokenChanged } from '../../services/DataService';
import useRouterGate from '../../util/RouterUtils/useRouterGate';
import { $$app } from './model';
import useMountedEvent from '../../hooks/useMountedEvent';
import Router from './Router';
import { useUnit } from 'effector-react';
import InfoDialog from '../InfoDialog/InfoDialog';
import BlockingDialog from '../BlockingDialog/BlockingDialog';
import TutorialVideosModal from '../TutorialVideosModal/TutorialVideosModal';

dayjs.extend(customParseFormat);

/**
 * Component for the main application.
 *
 * @returns {React.ReactNode} App
 */
function App() {
  useMountedEvent($$app.pageMounted);

  const { getAccessTokenSilently } = useAuth0WithErrorHandling();

  useAuth0Gate();
  useRouterGate();

  const loadComplete = useUnit($$app.$loadComplete);
  const userDetails = useUnit($$app.$userDetails);

  // This useEffect hook has the getAccessTokenSilently function as a dependency. It calls getTokenChanged and passes
  // the function to the DataService where it is stored in $getToken
  // This means that in getTokenFx when it calls getToken, it is actually calling getAccessTokenSilently
  // So, whenever the interceptor calls getTokenFx, it actually calls getAccessTokenSilently behind the scenes
  useEffect(() => {
    getTokenChanged(getAccessTokenSilently);
    return () => {
      getTokenChanged(null);
    };
  }, [getAccessTokenSilently]);

  if (!loadComplete) {
    return (
      <Box display="flex" justifyContent="center" width="100" height="100vh" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  // Wrapping UserProvider will expose the 'value' props of UserContext and UserDispatchContext to the React
  // components down the tree
  return (
    <UserContext.Provider value={userDetails}>
      <InfoDialog />
      <BlockingDialog />
      <TutorialVideosModal />
      <Router />
    </UserContext.Provider>
  );
}

export { App };
