import { compose } from 'ramda';
import { baseTheme } from './base-palette';

import { createTheme as createInitialTheme, responsiveFontSizes } from '@mui/material/styles';

const createTheme = compose(responsiveFontSizes, createInitialTheme);

const light = createTheme({
  ...baseTheme,
  themeName: 'Grayhair Light',
  palette: {
    mode: 'light',
    text: {
      primary: '#000000DE',
      secondary: '#00000099',
      disabled: '#00000061'
    },
    primary: {
      main: '#017397',
      dark: '#004869',
      light: '#4FA2C8',
      contrastText: '#FFFFFF',
      '8p': '#01739714',
      '12p': '#0173971F'
    },
    secondary: {
      main: '#2A393E',
      dark: '#001318',
      light: '#536369',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#EF5350',
      contrastText: '#FFFFFF',
      '4p': '#D32F2F0A'
    },
    warning: {
      main: '#C15700',
      dark: '#B04003',
      light: '#FF9800',
      contrastText: '#FFFFFF'
    },
    info: {
      main: '#037BBC',
      dark: '#01579B',
      light: '#03A9F4',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#2E7D32',
      dark: '#1B5E20',
      light: '#4CAF50',
      contrastText: '#FFFFFF'
    },
    // custom colors
    grey: {
      main: '#E0E0E0'
    },
    action: {
      active: '#0000008A',
      hover: '#0000000A',
      selected: '#00000014',
      disabled: '#00000042',
      disabledBg: '#0000001F',
      focus: '#0000001F'
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF'
    },
    misc: {
      dataGridHeader: '#0000001F',
      divider: '#0000001F',
      outlineBorder: '#0000001F',
      inputLine: '#0000006B',
      filledInputBg: '#0000000F',
      overlay: '#00000080',
      ratingActive: '#FFB400',
      backgroundSnackbar: '#001318',
      appBarInputBg: '#FFFFFF26',
      appBarInputBgHovered: '#FFFFFF40'
    },
    grayHair: {
      pink: '#D7006D'
    },
    toolips: {
      error: {
        background: '#fdeded',
        text: '#5f2120'
      },
      warning: {
        background: '#fff4e5',
        text: '#663c00'
      },
      info: {
        background: '#0288D1',
        text: 'text.primary'
      },
      success: {
        background: '#edf7ed',
        text: '#1e4620'
      }
    }
  }
});

const dark = createTheme({
  ...baseTheme,
  themeName: 'Grayhair Dark',
  palette: {
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFFB2',
      disabled: '#FFFFFF80'
    },
    primary: {
      main: '#229DC3',
      dark: '#176D88',
      light: '#4EB0CF',
      contrastText: '#000000DE',
      '8p': '#229DC314',
      '12p': '#229DC31F'
    },
    secondary: {
      main: '#A2BAC3',
      dark: '#718288',
      light: '#B4C7CF',
      contrastText: '#000000DE'
    },
    error: {
      main: '#F44336',
      dark: '#D32F2F',
      light: '#E57373',
      contrastText: '#FFFFFFDE',
      '4p': '#F443360A'
    },
    warning: {
      main: '#FFA726',
      dark: '#F57C00',
      light: '#FFB74D',
      contrastText: '#000000DE'
    },
    info: {
      main: '#29B6F6',
      dark: '#0288D1',
      light: '#4FC3F7',
      contrastText: '#FFFFFFDE'
    },
    success: {
      main: '#66BB6A',
      dark: '#388E3C',
      light: '#81C784',
      contrastText: '#000000DE'
    },
    // custom colors
    grey: {
      main: '#E0E0E0'
    },
    action: {
      active: '#FFFFFF8F',
      hover: '#FFFFFF14',
      selected: '#FFFFFF29',
      disabled: '#FFFFFF4D',
      disabledBg: '#FFFFFF1F',
      focus: '#FFFFFF1F'
    },
    background: {
      paper: '#121212',
      default: '#121212'
    },
    misc: {
      dataGridHeader: '#FFFFFF1F',
      divider: '#FFFFFF1F',
      dividerOutline: '#121212',
      borderInput: '#121212',
      filledInputBg: '#FFFFFF17',
      overlay: '#00000080',
      ratingActive: '#FFB400',
      backgroundSnackbar: '#ffffff',
      appBarInputBg: '#FFFFFF26',
      appBarInputBgHovered: '#FFFFFF40'
    },
    grayHair: {
      pink: '#D7006D'
    },
    toolips: {
      error: {
        background: '#160b0b',
        text: '#f4c7c7'
      },
      warning: {
        background: '#191207',
        text: '#ffe2b7'
      },
      info: {
        background: '#0288D1',
        text: 'text.primary'
      },
      success: {
        background: '#0c130d',
        text: '#cce8cd'
      }
    }
  }
});

export default { light, dark };
