export type UserDetails = {
  permissions: string[];
  personas: Persona[];
  activePersona: Persona;
  isMt: boolean;
  isSt: boolean;
};

export type DashboardView = {
  id: string;
  title: string;
  description: string;
  thumbnailLight: string;
  thumbnailDark: string;
  order: number;
};

export type SisenseAuthenticationStatus = 'NOT_AUTHENTICATED' | 'AUTHENTICATING' | 'AUTHENTICATION_FAILED' | 'AUTHENTICATED';

export const SisenseAuthenticationStatus = {
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  AUTHENTICATING: 'AUTHENTICATING',
  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
  AUTHENTICATED: 'AUTHENTICATED'
} as const;

export type SisenseInfo = {
  authenticationStatus: SisenseAuthenticationStatus;
  dashboardsNo: number;
  defaultDashboardId: string;
  dashboards: DashboardView[];
  reloadCount: number;
};

export type UserDashboardStatus = 'FIRST_AUTHENTICATION_ATTEMPT' | 'RETRYING_AUTHENTICATION' | 'MAX_AUTHENTICATION_RETRIES_REACHED' | 'USER_NOT_FULLY_SETUP' | 'USER_AUTHENTICATED_WITH_DASHBOARDS';

export const UserDashboardStatus = {
  FIRST_AUTHENTICATION_ATTEMPT: 'FIRST_AUTHENTICATION_ATTEMPT',
  RETRYING_AUTHENTICATION: 'RETRYING_AUTHENTICATION',
  MAX_AUTHENTICATION_RETRIES_REACHED: 'MAX_AUTHENTICATION_RETRIES_REACHED',
  USER_NOT_FULLY_SETUP: 'USER_NOT_FULLY_SETUP',
  USER_AUTHENTICATED_WITH_DASHBOARDS: 'USER_AUTHENTICATED_WITH_DASHBOARDS'
} as const;
