// @ts-ignore
import { buttonColors } from '@ghs/components';
import { GridRowModes } from '@mui/x-data-grid-pro';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage/query';
import { isEmpty, keyBy } from 'lodash';
import { debounce, delay, spread } from 'patronum';
import qs from 'qs';
import { pipe } from 'ramda';
import { createElement } from 'react';
import { $$campaignVersionsDialog } from '../../../components/CampaignVersionsDialog/model';
import { $$confirmationDialog } from '../../../components/ConfirmationDialog/model.js';
import { $$infoDialog } from '../../../components/InfoDialog/model.js';
import { $$snackbar } from '../../../components/SnackbarRoot/model.js';
import { $$reseller } from '../../../services/ResellerService/model.js';
import createDataGridModel from '../../../util/createDataGridModel.js';
import createFxStatuses from '../../../util/createFxStatuses.js';
import { treeShakeObject, VOID } from '../../../util/JsonUtils.js';
import CampaignDeleteConfirmationMessage from './CampaignDeleteConfirmationMessage.jsx';
import FailedCampaignUpdateDialogContent from './FailedCampaignUpdateDialogContent.jsx';
import { DEFAULT_MAIL_DATE_FILTER_ID, mailDateFilters } from './utils.js';

/**
 * @typedef {import('@mui/x-data-grid-pro').GridRowId} GridRowId
 * @typedef {import('@mui/x-data-grid-pro').GridValidRowModel} GridValidRowModel
 * @typedef {{ id: Reseller.Campaign['id'], field: keyof Reseller.Campaign, error: string }} ValidationError
 */

const $filters = createStore(/** @type {{ search?: string; mailDate?: string; customerId?: string; lineOfBusinessIds?: string }} */ ({ mailDate: DEFAULT_MAIL_DATE_FILTER_ID }));

const $customers = createStore(/** @type {Reseller.Customer[]} */ ([]));

const $linesOfBusiness = createStore(/** @type {Reseller.LineOfBusiness[]} */ ([]));

/** @type {import('effector').EventCallable<{ key: keyof (typeof $filters)['__']; value: unknown }>} */
const setFilterByKey = createEvent();

/** @type {import('effector').EventCallable<Reseller.Campaign['id']>} */
const deleteClicked = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const saveClicked = createEvent();

/** @type {import('effector').EventCallable<void>} */
const refetch = createEvent();

/** @type {import('effector').EventCallable<Reseller.Campaign['id']>} */
const cancelClicked = createEvent();

/** @type {import('effector').EventCallable<Reseller.Campaign['id']>} */
const eyeIconClicked = createEvent();

/** @type {import('effector').EventCallable<void>} */
const openUnsavedChangesWarning = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const secondConfirmationTriggered = createEvent();

/** @type {(startDateField: string, endDateField: string) => import('effector').Store<{ search?: string; customerId?: string; lineOfBusinessIds?: string; }>} */
const generateFilters = (startDateField, endDateField) => {
  return $filters.map(({ mailDate, ...filters }) => {
    const mailDateFilter = mailDateFilters.find(f => f.id === (mailDate || DEFAULT_MAIL_DATE_FILTER_ID)) || null;
    if (!mailDateFilter) {
      return filters;
    }
    return {
      ...filters,
      [startDateField]: dayjs().startOf('day').add(mailDateFilter.mailDateLowerBoundDays, 'day').format('MM-DD-YYYY'),
      [endDateField]: dayjs().endOf('day').add(mailDateFilter.mailDateUpperBoundDays, 'day').format('MM-DD-YYYY')
    };
  });
};

/** @type {import('effector').Effect<Reseller.Campaign, boolean>} */
const validateCampaignCandidateFx = createEffect(async ({ customer, name, number, lineOfBusiness }) => {
  return $$reseller.getCampaignsFx({ customersIds: customer.id, campaignNumber: number, campaignName: name, lineOfBusinessIds: lineOfBusiness.id }).then(result => result.numberOfElements === 0);
});

const getFreshRowsFx = attach({
  source: sample({
    source: { dates: generateFilters('mailDateStart', 'mailDateEnd'), filters: $filters.map(({ customerId, lineOfBusinessIds }) => ({ customerIds: customerId, lineOfBusinessIds })) },
    fn: ({ dates, filters }) => treeShakeObject({ ...dates, ...filters })
  }),
  /** @type {(params: DataGrid.ServerSideParams, filters: (typeof $filters)['__']) => object} */
  mapParams: (pagination, filters) => treeShakeObject({ ...pagination, ...filters, persistResult: true }),
  effect: $$reseller.getCampaignsFx
});

const $$campaigns = createDataGridModel({
  type: 'server',
  effect: getFreshRowsFx,
  refetch
});

const $$validationStatuses = createFxStatuses(validateCampaignCandidateFx);

// Wrap the effects to be able to handle the done event in each page separately and avoid overlaps
const getLineOfBusinessFx = attach({ effect: $$reseller.getLineOfBusinessFx });

sample({
  source: $filters,
  clock: setFilterByKey,
  fn: (filters, { key, value }) => {
    let newFilters

    if (key === 'customerId') {
      newFilters = { ...filters, lineOfBusinessIds: null, [key]: value };
    } else {
      newFilters = { ...filters, [key]: value };
    }

    return newFilters
  },
  target: $filters
});

persist({
  store: $filters,
  key: 'filters',
  serialize: pipe(treeShakeObject, qs.stringify),
  deserialize: qs.parse
});

sample({
  clock: delay(secondConfirmationTriggered, 0), // Delay is needed to let the confirmation dialog close before opening it again
  fn: id => ({
    confirmation: {
      title: 'Success!',
      content: 'All your changes have been successfully applied.',
      acceptButtonText: 'Save and exit',
      closeButtonText: 'Undo Changes',
      onAccept: () => {
        $$reseller.deleteCampaignsFx(id);
      }
    },
    highlight: [id]
  }),
  target: spread({ confirmation: $$confirmationDialog.open, highlight: $$campaigns.highlightRows })
});

sample({
  source: $$campaigns.$data,
  clock: deleteClicked,
  fn: (campaigns, id) => ({
    title: 'Campaign deletion confirmation',
    // @ts-ignore
    content: createElement(CampaignDeleteConfirmationMessage, { campaign: campaigns.find(c => c.id === id) }),
    onAccept: () => secondConfirmationTriggered(id),
    acceptButtonText: 'Delete',
    acceptButtonColor: buttonColors.ERROR
  }),
  target: $$confirmationDialog.open
});

sample({
  clock: $$reseller.deleteCampaignsFx.done,
  fn: ({ params: id }) => ({
    snackbar: { message: 'Campaign was deleted successfully', severity: /** @type {const} */ ('success') },
    resetRowChanges: id
  }),
  target: spread({
    snackbar: $$snackbar.open,
    resetRowChanges: $$campaigns.resetRowChanges
  })
});

sample({
  source: $$campaigns.$data,
  clock: $$reseller.deleteCampaignsFx.fail,
  fn: (campaigns, { params }) => {
    const campaign = campaigns.find(c => c.id === params);
    return { message: campaign ? `Campaign '${campaign.name} - ${campaign.number}' deleted` : 'Unexpected error', severity: /** @type {const} */ ('error') };
  },
  target: $$snackbar.open
});

sample({
  source: generateFilters('startDate', 'endDate'),
  clock: [$$campaigns.tableMounted.filter({ fn: Boolean }), $filters.updates],
  target: $$reseller.getCustomersFx
});

sample({
  clock: $$reseller.getCustomersFx.doneData,
  target: $customers
});

sample({
  source: {
    filters: generateFilters('startDate', 'endDate'),
    customerId: $filters.map(filters => filters.customerId || null)
  },
  clock: [$$campaigns.tableMounted.filter({ fn: Boolean }), $filters.updates],
  filter: ({ customerId }) => Boolean(customerId),
  fn: ({ filters, customerId }) => treeShakeObject({ ...filters, customerIds: customerId || null }),
  target: getLineOfBusinessFx
});

sample({
  clock: getLineOfBusinessFx.doneData,
  target: $linesOfBusiness
});

sample({
  clock: $filters.map(filters => filters.customerId === null).updates.filter({ fn: Boolean }),
  target: $linesOfBusiness.reinit
});

sample({
  clock: [debounce(sample({ clock: [$filters.updates] }), 1000), $$reseller.deleteCampaignsFx.done],
  target: refetch
});

sample({
  source: $$campaigns.$updatedRows,
  clock: saveClicked,
  filter: (updatedRows, id) => Boolean(keyBy(updatedRows, 'id')[id]),
  fn: (updatedRows, id) => keyBy(updatedRows, 'id')[id],
  target: $$reseller.updateCampaignsFx
});

sample({
  source: $$campaigns.$data,
  clock: $$reseller.updateCampaignsFx.done,
  fn: (data, { params: campaign }) => ({
    snackbarOpen: { message: `Campaign '${campaign.name} - ${campaign.number}' updated`, severity: /** @type {const} */ ('success') },
    setRowMode: { id: campaign.id, mode: GridRowModes.View },
    campaignIdToReset: campaign.id,
    updateData: data.map(row => (row.id === campaign.id ? campaign : row))
  }),
  target: spread({
    snackbarOpen: $$snackbar.open,
    setRowMode: $$campaigns.setRowMode,
    campaignIdToReset: $$campaigns.resetRowChanges,
    updateData: $$campaigns.$data
  })
});

sample({
  clock: $$reseller.updateCampaignsFx.fail,
  fn: ({ params: campaign, error }) => ({
    title: 'Campaign not updated',
    content: (
      <FailedCampaignUpdateDialogContent
        content={
          error instanceof AxiosError && typeof error.response?.data.message === 'string'
            ? error.response.data.message
            : `Campaign '${campaign.name} - ${campaign.number}' cannot be updated because it has already received tracking scans`
        }
      />
    )
  }),
  target: $$infoDialog.open
});

sample({
  source: $$campaigns.$updatedRows,
  clock: debounce($$campaigns.cellValueChanged, 100),
  fn: (updatedRows, { id }) => {
    const row = /** @type {Reseller.Campaign[]} */ (updatedRows).find(r => r.id === id);
    return row
      ? treeShakeObject({
          addCellError: [
            isEmpty(row?.name) && { id, field: /** @type {const} */ ('name'), error: 'Name is required' },
            isEmpty(row?.number) && { id, field: /** @type {const} */ ('number'), error: 'Number is required' }
          ].filter(Boolean),
          resetCellError: [!isEmpty(row?.name) && { id, field: /** @type {const} */ ('name') }, !isEmpty(row?.number) && { id, field: /** @type {const} */ ('number') }].find(Boolean),
          rowToValidate: isEmpty(row?.name) || isEmpty(row?.number) ? null : row
        })
      : {};
  },
  target: spread({ rowToValidate: validateCampaignCandidateFx, addCellError: $$campaigns.addCellError, resetCellError: $$campaigns.resetCellError })
});

sample({
  clock: validateCampaignCandidateFx.done,
  fn: ({ params, result }) =>
    result
      ? { resetCellError: { id: params.id, field: params.field } }
      : {
          setErrors: [
            { id: params.id, field: /** @type {const} */ ('name'), error: 'Campaign with the same name and number already exists' },
            { id: params.id, field: /** @type {const} */ ('number'), error: 'Campaign with the same name and number already exists' }
          ]
        },
  target: spread({ setErrors: $$campaigns.addCellError, resetCellError: $$campaigns.resetCellError })
});

// When filters are changed, check if there are unsaved changes and reset row modes to View
sample({
  source: $$campaigns.$unsavedChanges,
  clock: $filters.updates,
  filter: unsavedChanges => unsavedChanges.length === 0,
  fn: () => GridRowModes.View,
  target: $$campaigns.setAllRowsMode
});

sample({
  clock: cancelClicked,
  fn: id => ({ setRowMode: { id, mode: GridRowModes.View }, resetUnsavedChanges: id }),
  target: spread({ setRowMode: $$campaigns.setRowMode, resetUnsavedChanges: $$campaigns.resetRowChanges })
});

sample({
  clock: openUnsavedChangesWarning,
  fn: () => ({
    title: 'Unsaved changes',
    content: 'You have unsaved changes. Please save or discard them before leaving the page.',
    buttons: [{ children: 'Cancel', onClick: () => $$confirmationDialog.close() }]
  }),
  target: $$confirmationDialog.open
});

const $hasUnsavedChanges = $$campaigns.$unsavedChanges.map(changes => changes.length > 0);

sample({
  source: $hasUnsavedChanges,
  clock: eyeIconClicked,
  fn: (hasUnsavedChanges, campaignId) => (hasUnsavedChanges ? { openUnsavedChangesWarning: VOID } : { openVersionsDialog: { campaignId } }),
  target: spread({ openUnsavedChangesWarning: openUnsavedChangesWarning, openVersionsDialog: $$campaignVersionsDialog.open })
});

// @ts-ignore
window.navigation.addEventListener('navigate', event => {
  if ($hasUnsavedChanges.getState()) {
    openUnsavedChangesWarning();
    event.preventDefault();
  }
});

window.onbeforeunload = function () {
  if ($hasUnsavedChanges.getState()) {
    return true;
  }
};

export const $$adminCampaignsPage = {
  $$campaigns,
  $$validationStatuses,

  $filters: $filters.map(filters => ({ ...filters })),
  $linesOfBusiness: $linesOfBusiness.map(d => d),
  $customers: $customers.map(d => d),

  setFilterByKey,
  deleteClicked,
  saveClicked,
  cancelClicked,
  eyeIconClicked
};
