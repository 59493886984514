import { DashboardView, SisenseAuthenticationStatus, SisenseInfo } from './types.ts';
import { minBy } from 'lodash';

export const SISENSE_THEMES_MAP: Record<'light' | 'dark', string> = {
  light: import.meta.env.VITE_BI_SERVER_GRAYHAIR_LIGHT_THEME_ID,
  dark: import.meta.env.VITE_BI_SERVER_GRAYHAIR_DARK_THEME_ID
};

export const createSisenseInfo = (authenticationStatus: SisenseAuthenticationStatus, dashboards: DashboardView[] = [], reloadCount: number = 0): SisenseInfo => ({
  authenticationStatus,
  dashboards: dashboards,
  dashboardsNo: (dashboards || []).length,
  // Set the default dashboard as the one with the minimum order
  defaultDashboardId: minBy(dashboards || [], d => d.order)?.id ?? null,
  reloadCount: reloadCount
});

export const SISENSE_INFO_NOT_AUTHENTICATED: SisenseInfo = createSisenseInfo(SisenseAuthenticationStatus.NOT_AUTHENTICATED);
export const SISENSE_INFO_AUTHENTICATING: SisenseInfo = createSisenseInfo(SisenseAuthenticationStatus.AUTHENTICATING);
export const SISENSE_INFO_AUTHENTICATION_FAILED: SisenseInfo = createSisenseInfo(SisenseAuthenticationStatus.AUTHENTICATION_FAILED);
export const RELOAD_SISENSE_INFO_MAX_RETRIES = 3;

export class BiAuthenticationError extends Error {
  constructor() {
    super(`BI server auth Error`);
  }
}
