import { BlogCard } from '@ghs/components';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { $$infoDialog } from './InfoDialog/model';

/**
 * @typedef {import('../services/BiService/types.ts').DashboardView} DashboardView
 */

/**
 * Component for displaying a thumbnail card.
 *
 * @param {DashboardView & {
 *   link: string | undefined;
 *   theme: 'light' | 'dark' | undefined;
 * }} props - The props of the component.
 * @returns {React.ReactNode} BiThumbnailCard component
 */
export default function BiThumbnailCard(props) {
  const { id, title, link, description, thumbnailLight, thumbnailDark, theme: propsTheme } = props;

  const theme = useTheme();

  const thumbnail = ((propsTheme || theme.palette.mode) === 'dark' ? thumbnailDark : thumbnailLight) || '';

  const suffix = (thumbnail.startsWith('data:image/') || thumbnail.startsWith('blob:') ? '#' : '?') + Date.now();

  return (
    <Box id={'thumbnail-card-' + id} key={id + '-box'} sx={{ display: 'flex', justifyContent: 'center', height: '95%' }}>
      <BlogCard
        key={id + '-card'}
        header={title}
        imgSrc={`${thumbnail}${suffix}`}
        imgAlt={+(description || null) === 0 ? ' ' : description} // Space is added to make thumbnail visible
        LinkProps={link ? { component: RouterLink, to: link } : null}
        cardActionsProps={{ disableSpacing: true, sx: { mt: 'auto' } }}
        actions={[{ label: 'Learn more', onClick: () => $$infoDialog.open({ title, content: description, theme: propsTheme || theme.palette.mode }) }]}
        sx={{ m: 1, width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      />
    </Box>
  );
}

BiThumbnailCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnailLight: PropTypes.string,
  thumbnailDark: PropTypes.string,
  link: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark'])
};
