import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';

type DrawerHeaderProps = {
  theme: Theme;
};

export const DrawerHeader = styled('div')(({ theme }: DrawerHeaderProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
