// @ts-ignore
import { Dialog, dialogMaxWidths } from '@ghs/components';
import { useUnit } from 'effector-react';
import { $$blockingDialog } from './model';

/**
 * BlockingDialog - a dialog without any buttons that must be opened and closed programmatically.
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function BlockingDialog() {
  const open = useUnit($$blockingDialog.$isOpen);
  const state = useUnit($$blockingDialog.$state);

  if (!state) {
    return null;
  }

  const { title, content } = state;

  return (
    <Dialog title={title} open={open} fullWidth={true} maxWidth={dialogMaxWidths.XS} data-testid="blocking-dialog">
      {content}
    </Dialog>
  );
}
