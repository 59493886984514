import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PhoneIcon from '@mui/icons-material/Phone';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  hasAddressVerificationPermission,
  hasAdminEditCampaignsVersionsPermissions,
  hasAdminEditCustomersLobsPermissions,
  hasAdminInHomeWindowsPermissions,
  hasAdminLogosPermissions,
  hasAdminManageUsersDataAccessPermissions,
  hasAdminPermissions,
  hasAdminRolesPermissionsPermissions,
  hasCreateCampaignPermission,
  hasDashboardPermission,
  hasFileProcessingPermission,
  hasGhsAdminBannerPermissions,
  hasGhsAdminDashboardThumbnailsPermissions,
  hasGhsAdminLogoPermissions,
  hasGhsAdminPermissions,
  hasMailTrakAutoRatingPermission,
  hasMailTrakClientLobPermission,
  hasSinglePieceLookupPermission
} from '../../services/UserService/UserService';
import * as React from 'react';
import { SisenseAuthenticationStatus, SisenseInfo } from '../../services/BiService';
import { CircularProgress, Theme } from '@mui/material';
import { tooltipSeverities } from '@ghs/components';

export type DisableFunctionResult = {
  icon: React.ReactElement;
  severity: (typeof tooltipSeverities)[keyof typeof tooltipSeverities];
  tooltip: string;
};

export type SubPage = {
  name: string;
  path: string;
  exclude?: boolean | ((permissions: string[], dashboardsInfo?: SisenseInfo | null) => boolean);
  showBanners?: boolean;
};

export type Page = {
  name: string;
  path?: string;
  icon?: React.ReactElement;
  exclude?: boolean | ((permissions: string[], dashboardsInfo?: SisenseInfo | null) => boolean);
  disable?: (dashboardsInfo?: SisenseInfo | null, theme?: Theme) => DisableFunctionResult;
  showBanners?: boolean;
  subPages?: SubPage[];
  isExternalLink?: boolean;
};

type FlattenedPage = (Page | SubPage) & { fullName: string };

/**
 * List of pages in the application.
 *
 */
const pages: Page[] = [
  { name: 'Home', path: '/', icon: <HomeIcon />, showBanners: true },
  {
    name: 'Dashboards',
    path: '/dashboards',
    icon: <AnalyticsIcon />,
    exclude: permissions => !hasDashboardPermission(permissions),
    disable: (dashboardsInfo, theme) => {
      if (dashboardsInfo?.authenticationStatus === SisenseAuthenticationStatus.NOT_AUTHENTICATED) {
        return {
          icon: <CircularProgress data-testid="dashboards-menu-item-progress-icon" size="1.5rem" />,
          severity: tooltipSeverities.INFO,
          tooltip: 'Loading dashboards...'
        };
      }
      if (dashboardsInfo?.authenticationStatus === SisenseAuthenticationStatus.AUTHENTICATION_FAILED) {
        return {
          icon: <ErrorOutlineIcon data-testid="dashboards-menu-item-error-icon" sx={{ color: theme.palette.error.main }} />,
          severity: tooltipSeverities.ERROR,
          tooltip: 'Error loading dashboards. Contact support.'
        };
      }
      if (dashboardsInfo?.authenticationStatus === SisenseAuthenticationStatus.AUTHENTICATING) {
        return {
          icon: <WarningAmberIcon data-testid="dashboards-menu-item-warning-icon" sx={{ color: theme.palette.warning.main }} />,
          severity: tooltipSeverities.WARNING,
          tooltip: 'Error loading dashboards. The system is automatically retrying.'
        };
      }
      return null;
    },
    showBanners: true
  },
  { name: 'Single Piece Lookup', path: '/lookup', icon: <SearchIcon />, showBanners: true, exclude: permissions => !hasSinglePieceLookupPermission(permissions) },
  { name: 'Address Verification', path: '/addressVerification', icon: <ContactMailIcon />, showBanners: true, exclude: permissions => !hasAddressVerificationPermission(permissions) },
  {
    name: 'Create Campaigns',
    path: import.meta.env.VITE_GH_SELECT_URL,
    icon: <NoteAddIcon />,
    exclude: permissions => !hasCreateCampaignPermission(permissions),
    showBanners: false,
    isExternalLink: true
  },
  { name: 'SPL Results', path: '/splResults', exclude: true, showBanners: true },
  { name: 'Contact', path: '/contact', icon: <PhoneIcon />, showBanners: true },
  { name: 'FAQ', path: '/faq', icon: <HelpIcon />, showBanners: true },
  {
    name: 'GHS Admin',
    icon: <AdminPanelSettingsIcon />,
    exclude: permissions => !hasGhsAdminPermissions(permissions),
    subPages: [
      { name: 'Logos', path: '/ghsAdmin/logos', exclude: permissions => !hasGhsAdminLogoPermissions(permissions), showBanners: true },
      { name: 'Dashboard thumbnails', path: '/ghsAdmin/dashboards', exclude: permissions => !hasGhsAdminDashboardThumbnailsPermissions(permissions), showBanners: true },
      { name: 'Banners', path: '/ghsAdmin/banners', exclude: permissions => !hasGhsAdminBannerPermissions(permissions), showBanners: true },
      { name: 'File Processing', path: '/ghsAdmin/fileProcessing', showBanners: true, exclude: permissions => !hasFileProcessingPermission(permissions) },
      { name: 'MailTrak Client LOB', path: '/ghsAdmin/mailTrakClientLOB', showBanners: true, exclude: permissions => !hasMailTrakClientLobPermission(permissions) },
      { name: 'MailTrak Auto Rating', path: '/ghsAdmin/mailTrakAutoRating', showBanners: true, exclude: permissions => !hasMailTrakAutoRatingPermission(permissions) }
    ]
  },
  {
    name: 'Admin',
    icon: <SettingsIcon />,
    exclude: permissions => !hasAdminPermissions(permissions),
    subPages: [
      { name: 'Manage Users/Data Access', path: '/admin/data-access', exclude: permissions => !hasAdminManageUsersDataAccessPermissions(permissions), showBanners: true },
      { name: 'Roles/Permissions', path: '/admin/roles', exclude: permissions => !hasAdminRolesPermissionsPermissions(permissions), showBanners: true },
      { name: 'Edit Campaigns/Versions', path: '/admin/campaigns', exclude: permissions => !hasAdminEditCampaignsVersionsPermissions(permissions), showBanners: true },
      { name: 'In-Home Windows', path: '/admin/inHomeWindows', exclude: permissions => !hasAdminInHomeWindowsPermissions(permissions), showBanners: true },
      { name: 'Edit Customer/LOB', path: '/admin/customers', exclude: permissions => !hasAdminEditCustomersLobsPermissions(permissions), showBanners: true },
      { name: 'Logos', path: '/admin/logos', exclude: permissions => !hasAdminLogosPermissions(permissions), showBanners: true }
    ]
  },
  { name: 'Logout', path: '/logout', exclude: true },
  { name: 'Callback', path: '/callback', exclude: true },
  { name: 'Dashboard', path: '/dashboard', exclude: true }
];

/**
 * Get all pages flattened, that is, containing the subpages as pages.
 */
function getAllPagesFlattened(): FlattenedPage[] {
  return pages.reduce((result, page) => {
    if (!page.subPages) {
      result.push({ ...page, fullName: `${page.name}` });
    } else {
      // Add the subpages and don't add the parent page as it's not an actual page (just a menu item to
      // expand/collapse the subpages. For each subpage, add a new property "parentPageName"
      page.subPages.forEach(subPage => {
        result.push({ ...subPage, fullName: `${page.name} - ${subPage.name}` });
      });
    }
    return result;
  }, []);
}

export { getAllPagesFlattened, pages };
