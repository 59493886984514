import { Fragment } from 'react';
import { Box, Alert, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { $$contactService } from '../../services/ContactService/model';

export default function CuratingMessage() {
  const contactInfo = useUnit($$contactService.$contactInfo);
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box sx={{ maxWidth: 'lg' }}>
        <Alert id="curating-msg" severity="info" sx={{ mb: 2, ml: 3, mr: 3 }}>
          <Fragment>
            We know you are eager to see your data, but please be patient. Our data curation process is like a fine wine, it takes time to mature and improve. We are not just dumping some random
            numbers on your screen, we are crafting a masterpiece of information that is custom-made for you.
            <br />
            <br />
            You may wonder why we need to curate your data so carefully. Well, it’s because we care about your privacy and security. We don’t want anyone else to peek at your data or tamper with it.
            That’s why we use the latest encryption and auditing techniques to make sure your data is safe and sound.
            <br />
            <br />
            While you are waiting for your data to be ready, you can still use our{' '}
            <Link component={RouterLink} to={'/lookup'}>
              single piece lookup
            </Link>
            . This feature allows you to search for any individual piece of data that you need, without having to wait for the whole dataset. It’s like a snack before the main course. Just type in
            what you are looking for and we will fetch it for you in seconds.
            <br />
            <br />
            If something goes wrong and you still see this message after 2 hours of waiting, please
            {contactInfo?.showContactForm ? (
              <>
                {' '}
                let us know by using the{' '}
                <Link component={RouterLink} to={'/contact'}>
                  contact form
                </Link>
                . We will try to fix the problem as soon as possible.{' '}
              </>
            ) : (
              <> contact your tracking supplier. </>
            )}
            We apologize for any inconvenience caused by our meticulous data curation process.
          </Fragment>
        </Alert>
      </Box>
    </Box>
  );
}
