import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Button, Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Collapse, ListItemIcon } from '@mui/material';
import { Avatar, Tooltip } from '@ghs/components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BannerContainer from '../BannerContainer';
import { useIdleTimer } from 'react-idle-timer';
import useAuth0WithErrorHandling from '../../hooks/useAuth0WithErrorHandling';
import { useAuth0 } from '@auth0/auth0-react';
import { SessionTimeoutDialog, convertSecondsToMinutesAndSeconds } from '../SessionTimeoutDialog';
import BiDashboard from '../../pages/DashboardsPage/BiDashboard';
import { useUnit } from 'effector-react';
import { $$logo } from '../../services/LogoService.js';
import { UserMenu } from '../UserMenu';
import { mapValues } from 'lodash';
import { $$app } from '../App/model';
import { $$bi } from '../../services/BiService';
import { AppBar } from './AppBar';
import { DrawerHeader } from './DrawerHeader';
import { Drawer } from './Drawer';
import { Page } from './Pages';

const drawerWidth: number = 255;
const timeout = import.meta.env.VITE_SESSION_TIMEOUT * 1000;
const promptBeforeIdle = import.meta.env.VITE_SESSION_TIMEOUT_WARNING * 1000;

// Memoize the BiDashboard component and ignore prop updates to prevent rendering every time the layout renders
const MemoizedBiDashboard = React.memo(BiDashboard, () => true);

type LayoutProps = {
  pages: Page[];
};

/**
 * Layout component for the main layout for this application.
 */
export default function Layout({ pages }: LayoutProps) {
  const primaryLogoUrl = useUnit($$logo.$primaryLogo);
  const secondaryLogoUrl = useUnit($$logo.$secondaryLogo);
  const userDetails = useUnit($$app.$userDetails);
  const sisenseInfo = useUnit($$bi.$sisenseInfo);

  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = React.useState(localStorage.getItem('ghs-drawer-open') ? localStorage.getItem('ghs-drawer-open')?.toLowerCase() === 'true' : true);
  const navigate = useNavigate();
  const [avatarEl, setAvatarEl] = React.useState(null);
  const [timeoutDialogOpen, setTimeoutDialogOpen] = React.useState(false);
  const [inactivityTimer, setInactivityTimer] = React.useState(timeout);
  const { getAccessTokenSilently } = useAuth0WithErrorHandling();
  const sisenseContainerElement = React.createRef();
  const { user } = useAuth0();
  const [menuItemsOpen, setMenuItemsOpen] = React.useState(
    pages
      .filter(page => !page.subPages)
      .reduce((acc, page) => {
        acc[page.name] = false;
        return acc;
      }, {})
  );

  const handleMenuItemClick = page => {
    if (page.subPages) {
      handleSubMenuItemClick(page.name);
    } else if (page.isExternalLink) {
      window.open(page.path, '_blank').focus();
    } else {
      navigate(page.path);
    }
  };

  const handleSubMenuItemClick = pageName => {
    // Open drawer if a submenu item is clicked
    if (!open) {
      handleDrawerOpen();
    }
    setMenuItemsOpen(prevState => ({
      ...prevState,
      [pageName]: !prevState[pageName]
    }));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem('ghs-drawer-open', 'true');
  };

  const handleDrawerClose = () => {
    // Close submenus when the drawer closes
    setMenuItemsOpen(prevState => mapValues(prevState, () => false));
    setOpen(false);
    localStorage.setItem('ghs-drawer-open', 'false');
  };

  const handleAvatarClick = e => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const getUserInitials = () => {
    if (user?.given_name && user?.family_name) {
      return `${user.given_name.charAt(0)}${user.family_name.charAt(0)}`.toUpperCase();
    } else if (user?.name) {
      return user.name.charAt(0).toUpperCase();
    }
    return '';
  };

  const stringToColour = str => {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, '0');
    }
    return colour;
  };

  const onIdle = () => {
    setTimeoutDialogOpen(false);
    navigate('/logout');
  };

  const onActive = () => {
    setTimeoutDialogOpen(false);
  };

  const onPrompt = () => {
    setTimeoutDialogOpen(true);
  };

  //Callback when receive message from another idle timer instance
  const onMessage = () => {
    activate();
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    onMessage,
    timeout,
    promptBeforeIdle,
    throttle: 5000,
    crossTab: true,
    syncTimers: 500
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      setInactivityTimer(Math.floor(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <AppBar color="secondary" enableColorOnDark position="fixed" open={open} drawerWidth={drawerWidth}>
        <Toolbar sx={{ px: 1.5, bgcolor: 'primary.dark' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' })
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ height: '50px', maxWidth: '240px', pt: 1, pb: 1 }}>
            {React.useMemo(
              () => (
                <img src={primaryLogoUrl} alt="GrayHair Logo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              ),
              [primaryLogoUrl]
            )}
          </Box>
          <Box sx={{ height: '50px', maxWidth: '240px', pt: 1, pb: 1, ml: 2 }}>
            {React.useMemo(() => {
              return secondaryLogoUrl ? (
                <img src={secondaryLogoUrl} alt="GrayHair Logo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              ) : (
                <Typography variant="h6" noWrap sx={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                  Client Portal
                </Typography>
              );
            }, [secondaryLogoUrl])}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {/* Countdown that doesn't render in production environments. When it completes it will show the session timeout dialog. */}
          <Box sx={{ ...(import.meta.env.VITE_SHOW_SESSION_TIMER !== 'true' && { display: 'none' }) }}>{convertSecondsToMinutesAndSeconds(Math.max(inactivityTimer - promptBeforeIdle / 1000, 0))}</Box>
          <Button onClick={handleAvatarClick} data-testid="user-avatar-button">
            {/* Use picture if available. Otherwise, generate avatar with initials and generated color. */}
            <Avatar src={user?.picture} sx={{ bgcolor: stringToColour(getUserInitials()) }} alt="User Profile Avatar">
              {getUserInitials()}
            </Avatar>
          </Button>
        </Toolbar>
      </AppBar>
      <UserMenu anchorEl={avatarEl} user={user} handleClose={handleAvatarClose} />
      <Drawer variant="permanent" open={open} drawerWidth={drawerWidth} PaperProps={{ sx: { backgroundColor: theme.palette.background.default } }}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pages.map(page => {
            const isExcluded = page.exclude instanceof Function ? page.exclude(userDetails?.permissions) : page.exclude;
            const disableInfo = page.disable instanceof Function ? page.disable(sisenseInfo, theme) : null;
            return (
              !isExcluded && (
                <ListItem
                  data-testid={`${page.name}-menu-item`}
                  key={page.name}
                  disablePadding
                  sx={{ display: 'block', ...(location.pathname === page.path && { backgroundColor: 'action.selected' }) }}
                >
                  <Tooltip
                    title={disableInfo?.tooltip ? disableInfo?.tooltip : ''}
                    data-testid={`${page.name}-menu-item-tooltip`}
                    arrow
                    severity={disableInfo?.severity}
                    opacity={'OPACITY_100'}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -20]
                            }
                          }
                        ]
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5
                        }}
                        onClick={() => handleMenuItemClick(page)}
                        disabled={!!disableInfo}
                        data-testid={`${page.name}-menu-item-button`}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center'
                          }}
                        >
                          {page.icon}
                        </ListItemIcon>
                        <ListItemText primary={page.name} primaryTypographyProps={{ fontSize: theme.typography.fontSize }} sx={{ opacity: open ? 1 : 0 }} />
                        {page.subPages && (menuItemsOpen[page.name] ? <ExpandLess /> : <ExpandMore />)}
                      </ListItemButton>
                      {disableInfo?.icon && (
                        <Box sx={{ ml: open ? 0 : -0.5, mr: 1 }} data-testid={`${page.name}-menu-item-disabled-icon`}>
                          {disableInfo.icon}
                        </Box>
                      )}
                    </Box>
                  </Tooltip>
                  {page.subPages?.map((subPage, index) => {
                    const isSubPageExcluded = subPage.exclude instanceof Function ? subPage.exclude(userDetails.permissions) : subPage.exclude;
                    return (
                      !isSubPageExcluded && (
                        <Collapse key={`subPage-${index}`} in={menuItemsOpen[page.name]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                justifyContent: menuItemsOpen[page.name] ? 'initial' : 'center',
                                px: 2.5,
                                pl: 4
                              }}
                              onClick={() => navigate(subPage.path)}
                            >
                              <ListItemText primary={subPage.name} primaryTypographyProps={{ fontSize: theme.typography.fontSize }} sx={{ opacity: menuItemsOpen[page.name] ? 1 : 0 }} />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      )
                    );
                  })}
                </ListItem>
              )
            );
          })}
        </List>
      </Drawer>
      <Box component="main" sx={{ flex: 1, overflow: 'hidden', p: 3, backgroundColor: theme.palette.background.paper }} id="outlet-box">
        <DrawerHeader />
        <BannerContainer />
        <Box data-testid="home-dashboard-container" sx={{ display: location.pathname === '/' ? 'block' : 'none' }}>
          <MemoizedBiDashboard
            key={sisenseInfo?.reloadCount || 1}
            unmountShouldDestroyFrame={false}
            unmountShouldUnloadEmbedSdk={false}
            containerElement={sisenseContainerElement}
            containerElementId="home-dashboard-container"
          />
        </Box>
        <Outlet />
      </Box>
      <SessionTimeoutDialog
        open={timeoutDialogOpen}
        onClose={async () => {
          setTimeoutDialogOpen(false);
          activate();
          // Trigger a refresh of the access token
          await getAccessTokenSilently({ cacheMode: 'off' });
        }}
        logoutFn={() => {
          setTimeoutDialogOpen(false);
          navigate('/logout');
        }}
        secondsToLogout={inactivityTimer}
      />
    </Box>
  );
}
