/**
 * @typedef {import('../services/BiService/types.ts').DashboardView} DashboardView
 */

/**
 * Transforms the dashboards array to include the full URL for the thumbnails.
 *
 * @param {DashboardView[]} dashboards - The dashboards array.
 * @returns {DashboardView[]} The dashboards array with the full URL for the thumbnails.
 */
export function transformDashboardsThumbnailsUrls(dashboards) {
  return dashboards.map(dashboard => {
    return {
      ...dashboard,
      thumbnailLight: `/${dashboard.thumbnailLight}`,
      thumbnailDark: `/${dashboard.thumbnailDark}`
    };
  });
}

/**
 * Returns the thumbnail URL for the given dashboard.
 *
 * @param {string} url - The URL of the thumbnail.
 * @returns {Promise<{
 *   previewURL: string;
 *   base64: string;
 * }>} - The downsized image.
 */
export const downscaleImage = url => {
  const finalSize = 100000;
  return new Promise(resolve => {
    const handleOnLoadEnd = (reader, blob) => () => {
      return resolve({
        previewURL: URL.createObjectURL(blob),
        base64: reader.result.split(',')[1]
      });
    };

    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const approximateSizeInBytes = img.height * img.width * 2;
      const multiplicator = approximateSizeInBytes / finalSize;
      canvas.width = img.width / Math.ceil(Math.sqrt(multiplicator));
      canvas.height = canvas.width * (img.height / img.width);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = handleOnLoadEnd(reader, blob);
      });
    };

    img.src = url;
  });
};
