import { Button, TextField } from '@ghs/components';
import { ExpandLess } from '@mui/icons-material';
import { alpha, Box, Collapse, Stack, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { useState } from 'react';
import { $$theme } from '../../services/ThemeService/model';
import SplDateRangePicker from './SplDateRangePicker';
import { FormErrors, SplFormByName } from './types';
import LookupSearchFilters from './LookupSearchFilters.tsx';
import { $$app } from '../../components/App/model';

type LookupByNameProps = {
  formData: Partial<SplFormByName>;
  errors: FormErrors;
  onChange: (value: Partial<SplFormByName>) => void;
};

export default function LookupByName(props: LookupByNameProps) {
  const { formData, errors, onChange } = props;

  const userDetails = useUnit($$app.$userDetails);
  const { palette } = useUnit($$theme.$palette);

  const [isUserFieldsExpanded, setIsUserFieldsExpanded] = useState<boolean>(false);

  function handleFormChange(event: React.ChangeEvent<HTMLInputElement>): void {
    onChange({ [event.target.name as keyof SplFormByName]: event.target.value });
  }

  return (
    <Stack sx={{ gap: 2 }}>
      <Stack direction="row" sx={{ gap: 2 }}>
        <Stack sx={{ gap: 2, flex: 1 }}>
          <TextField label="Full Name" name="name" value={formData.name || ''} onChange={handleFormChange} error={Boolean(errors.name)} helperText={errors.name} />
          <TextField label="Business Name" name="businessName" value={formData.businessName || ''} onChange={handleFormChange} error={Boolean(errors.businessName)} helperText={errors.businessName} />
          <TextField label="5-Digit ZIP" name="zip" value={formData.zip || ''} onChange={handleFormChange} error={Boolean(errors.zip)} helperText={errors.zip} />
          <TextField label="City" name="city" value={formData.city || ''} onChange={handleFormChange} error={Boolean(errors.city)} helperText={errors.city} />
          <SplDateRangePicker formData={formData} errors={errors} />
        </Stack>
        <Stack sx={{ gap: 2, flex: 1 }}>
          {userDetails?.isSt && (
            <>
              <Button
                variant="contained"
                sx={{
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  width: '100%',
                  background: alpha(palette.secondary.main, 0.05),
                  boxShadow: 'none',
                  ':hover': {
                    background: alpha(palette.secondary.main, 0.05)
                  },
                  textTransform: 'none',
                  borderRadius: 0,
                  p: 1,
                  height: '56px',
                  cursor: 'pointer',
                  alignItems: 'center'
                }}
                onClick={() => setIsUserFieldsExpanded(!isUserFieldsExpanded)}
              >
                <Typography color="text.primary">User Fields</Typography>
                <ExpandLess sx={{ color: 'action.active', fontSize: '3rem', transform: `rotate(${isUserFieldsExpanded ? '0deg' : '180deg'})`, transition: 'transform 0.2s ease-in-out' }} />
              </Button>
              <Collapse in={isUserFieldsExpanded} sx={{ mt: -1.5 }}>
                <Stack sx={{ gap: 2, flex: 1, mt: 1.5 }}>
                  <TextField label="User field 1" name="userField1" value={formData.userField1 || ''} onChange={handleFormChange} error={Boolean(errors.userField1)} helperText={errors.userField1} />
                  <TextField label="User field 2" name="userField2" value={formData.userField2 || ''} onChange={handleFormChange} error={Boolean(errors.userField2)} helperText={errors.userField2} />
                  <TextField label="User field 3" name="userField3" value={formData.userField3 || ''} onChange={handleFormChange} error={Boolean(errors.userField3)} helperText={errors.userField3} />
                </Stack>
              </Collapse>
            </>
          )}
        </Stack>
      </Stack>
      <Box direction="row" sx={{ width: '75%' }}>
        <LookupSearchFilters formData={formData} onChange={onChange} />
      </Box>
    </Stack>
  );
}
