import { createEffect, createStore } from 'effector';
import { ghApi } from '../DataService.ts';

const $faqs = createStore<FaqEntry[]>([
  {
    question: 'Why are my scan rates fluctuating?',
    answer:
      'As additional scan data becomes available from the USPS® and third-party data providers, values in our reports undergo changes. For instance, the Delivery Scan Summary Dashboard may indicate the delivery of 1,000 pieces on Tuesday, but the following day, the reported number may decrease to 965 pieces for Tuesday&apos;s deliveries. These fluctuations are a result of updated scan data altering the status of individual pieces. Such changes may stem from various factors, including potential redirection or forwarding due to undeliverable addresses, or delays in delivery, followed by subsequent updates by the USPS.'
  },
  {
    question: 'How often is the data in my dashboards updated?',
    answer: 'Dashboard data sources are updated every 4 hours during the week, and twice per day on weekends.'
  },
  {
    question: 'When will I see my campaigns, and for how long can I see them on the dashboards?',
    answer:
      'Scans are associated with pieces mailed 14 days prior to the mail date, so that is when versions and campaigns will be added to the portal. Data will be kept online for 14 months from the last mail date. After this, the data will be removed from the dashboard data sources. The Enterprise Summary Dashboard contains five years of data.'
  },
  {
    question: 'How is it determined if a mailpiece was delivered Pre In-Home, In-Home, or Post In-Home?',
    answer:
      'If an InHome window was provided, the pieces delivered within the specified dates are determined as the InHome window count. Any pieces delivered before or after the specified dates are categorized as early or late, respectively. For example, if you designate Tuesday through Thursday as your InHome window, mailpieces delivered on Tuesday, Wednesday, and Thursday are considered as meeting the InHome window delivery criteria. Conversely, pieces delivered on Monday or earlier are categorized as early, while those delivered on Friday or later are classified as late. In cases where no InHome window was provided, standard procedures are applied.'
  },
  {
    question: 'What would cause my dashboard to be blank?',
    answer:
      'There are several reasons your dashboard may be blank. For example, there may be no campaigns available during the date range selected or no scan data has been received for the campaign. Also, for some dashboards, there must be activated services such as ACS and Informed Delivery in order to see that data on the dashboards.'
  },
  {
    question: 'How do I export a dashboard?',
    answer:
      'There is a PDF button in the upper right corner to export the dashboard as a PDF. There are also three dots in the upper right corner of each dashboard that allow you to download the PDF or the image. In the Tabular Data tabs there are three dots in the upper right of that widget that allows you to download the PDF, image, CSV or Excel.'
  },
  {
    question: 'What are the options for downloading the data from the dashboards?',
    answer: 'In the Tabular Data tabs there are three dots in the upper right of that widget that allows you to download the PDF, image, CSV or Excel.'
  },
  {
    question: "Why can't I see anything the first time I log in?",
    answer:
      'New SSO users may have up to a 2-hour delay to be able to view dashboards after their initial logon. New users who have been granted access may be able to access the dashboards immediately once receiving their login credentials/instructions.'
  },
  {
    question: 'Are there additional dashboards available that I can add to my account?',
    answer:
      'Depending on the features and products you have contracted for and currently utilize within our system, your access to various dashboards may vary. If there are specific requirements or a need for custom dashboards to support valuable integrations, our team can create them upon request. For further information on the available dashboard options, please don&apos;t hesitate to reach out to your dedicated representative.'
  },
  {
    question: 'What are the default values for dashboards?',
    answer:
      'The default data is all data that is available to that user, so the full 14 months of data that is populated in that dashboard. You can use the filters in each dashboard to restrict the view to the chosen criteria. Once filters are chosen then those criteria will be saved unless the user resets the filters. You can also click the three dots in the top right corner of the dashboard and select &apos;restore dashboard&apos; and that will clear all the changes made to the filters and changes made to the Interactive Pivot in the tabular view.'
  },
  {
    question: 'Which browsers are supported?',
    answer: 'The most recent versions of Chrome, FireFox and Edge are recommended. Other browsers have not been fully tested for compatibility.'
  },
  {
    question: 'If I provide third party scan files, which dashboards display the data?',
    answer: 'Third Party Performance will show how your mail is performing when leveraging third party events. In the future we may leverage that data in additional dashboards.'
  }
]);

const getGcpVersionsFx = createEffect<void, GcpVersion[]>(async () => {
  return ghApi.get('/gcpVersions/releaseNotes').then(res => res.data);
});

export const $$gcp = {
  $faqs,

  getGcpVersionsFx
};
