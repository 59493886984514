import { createEvent, createStore, sample } from 'effector';
import { createDialog } from '../../util/createDialog';
import { VOID } from '../../util/JsonUtils';
import { $$gcp } from '../../services/GcpService/model.ts';
import { $$snackbar } from '../../components/SnackbarRoot/model';
import { spread } from 'patronum';

const $$releaseNotesDialog = createDialog();

//-------------------------------- Events --------------------------------
const reinitAll = createEvent<void>();
const pageMounted = createEvent<boolean>();
const releaseNotesButtonClicked = createEvent<void>();

//-------------------------------- Stores --------------------------------
const $gcpVersions = createStore<GcpVersion[]>(null).reset(reinitAll);

//-------------------------------- Samples --------------------------------
sample({
  clock: releaseNotesButtonClicked,
  target: [$$releaseNotesDialog.open, $$gcp.getGcpVersionsFx]
});

sample({
  clock: $$gcp.getGcpVersionsFx.doneData,
  target: $gcpVersions
});

sample({
  clock: $$gcp.getGcpVersionsFx.fail,
  fn: () => ({
    gcpVersions: [],
    closeDialog: VOID,
    snackbarMessage: { message: `Error loading release notes`, severity: 'error' }
  }),
  target: spread({
    gcpVersions: $gcpVersions,
    closeDialog: $$releaseNotesDialog.close,
    snackbarMessage: $$snackbar.open
  })
});

export const $$faq = {
  $$releaseNotesDialog,

  $gcpVersions,

  pageMounted,
  releaseNotesButtonClicked,

  __: {
    reinitAll
  }
};
