import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

/**
 * Component for a route that requires authentication to access.
 *
 * @param {{
 *   component: React.ComponentType;
 *   checkAccessFn?: () => boolean;
 * }} props - The props of the component.
 * @returns {React.ReactNode} ProtectedRoute
 */
const ProtectedRoute = props => {
  const { component: Component, checkAccessFn, ...args } = props;
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated && checkAccessFn && !checkAccessFn()) {
    return <Navigate to={'/notfound'} />;
  }

  return <Component {...args} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  checkAccessFn: PropTypes.func
};

export default ProtectedRoute;
