import { Container } from '@mui/material';
import { hasDashboardPermission, hasSinglePieceLookupPermission } from '../services/UserService/UserService';
import Contact from './Contact';
import SinglePieceLookupPage from './SinglePieceLookupPage/SinglePieceLookupPage';
import { useUnit } from 'effector-react';
import { $$app } from '../components/App/model';

export default function Home() {
  const userDetails = useUnit($$app.$userDetails);

  if (!userDetails) {
    // A blocking dialog with an error will show up when user details are not loaded
    // Could just return null but returning a container allows us to use the test id
    return <Container data-testid="home-empty-container" />;
  } else if (hasDashboardPermission(userDetails.permissions)) {
    // Cached dashboard in Layout will display
    return <Container data-testid="home-dashboard-empty-container" />;
  } else if (hasSinglePieceLookupPermission(userDetails.permissions)) {
    return (
      <Container>
        <SinglePieceLookupPage />
      </Container>
    );
  } else {
    return (
      <Container>
        <Contact />
      </Container>
    );
  }
}
