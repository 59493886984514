import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Container, Typography } from '@mui/material';
import { $$bi } from '../services/BiService/model';

const StartLogout = () => {
  const { logout } = useAuth0();

  $$bi.performBiServerLogoutFx();

  logout({ logoutParams: { returnTo: window.location.origin } });

  return (
    <Container>
      <Typography variant="h6" color="text.primary">
        Logging out...
      </Typography>
    </Container>
  );
};

export default StartLogout;
